import { Injectable } from '@angular/core';
import { BehaviorSubject, distinctUntilChanged, shareReplay } from 'rxjs';
import { RemoveNotificationModel } from '../components/notifications-list/notifications-list.service';

@Injectable({
  providedIn: 'root',
})
export class ApplicationStateService {
  private _refreshNotifications = new BehaviorSubject<
    RemoveNotificationModel | undefined
  >(undefined);
  refreshNotifications$ = this._refreshNotifications.asObservable();

  private _toggleSideBar = new BehaviorSubject<void>(undefined);
  toggleSideBar$ = this._toggleSideBar.asObservable();

  private _selectedChatGroupUid = new BehaviorSubject<string | undefined>(
    undefined
  );
  selectedChatGroupUid$ = this._selectedChatGroupUid.asObservable();

  private _languageChanged = new BehaviorSubject<string>('sv');
  languageChanged$ = this._languageChanged
    .asObservable()
    .pipe(distinctUntilChanged(), shareReplay());

  set languageChanged(language: string) {
    this._languageChanged.next(language);
  }

  get language() {
    return this._languageChanged.value ?? 'en';
  }

  selectedChatGroupUidChanged(selectedChatGroupUid: string | undefined) {
    this._selectedChatGroupUid.next(selectedChatGroupUid);
  }

  sideBarEvent() {
    this._toggleSideBar.next();
  }

  refreshNotifications(model: RemoveNotificationModel) {
    this._refreshNotifications.next(model);
  }
}
